<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "DropdownTitle",

  computed: {
    text() {
      return this.$parent.dropdownTitle.text;
    },

    href() {
      return this.$parent.dropdownTitle.href;
    },
  },
};
</script>
