<template>
  <div
    v-show="show"
    class="dropdown header__dropdown bg-primary"
    :style="{ width: navigationWidth + 'px' }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Mainnavigation",

  data() {
    return {
      navigationWidth: 0,
      resizeObserver: null,
      show: false,
    };
  },

  mounted() {
    this.setNavigationWidth();

    this.resizeObserver = new ResizeObserver(() => {
      this.setNavigationWidth();
    });

    const navigationWrapper = document.querySelector(".navigation__wrapper");
    if (navigationWrapper) {
      this.resizeObserver.observe(navigationWrapper);
    }

    window.addEventListener("resize", this.setNavigationWidth);
  },

  methods: {
    setNavigationWidth() {
      const navigationWrapper = document.querySelector(".navigation__wrapper");
      if (navigationWrapper) {
        this.navigationWidth = navigationWrapper.offsetWidth;
      }
    },

    isDropdownDisabled() {
      if (this.navbarToggler) {
        if (this.navbarToggler.offsetParent) {
          return true;
        }
      }
      return false;
    },

    setShowState(value) {
      this.show = value;
    },
  },

  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }

    window.removeEventListener("resize", this.setNavigationWidth);
  },
};
</script>
