// ACHTUNG!! Muss JSON sein, keine Komentare!
export default {
  entrypoints: {
    navigation: { php: false },
    contact: { php: false },
    flexparken: { php: false },
    dauerparken: { php: false },
    searchform: { php: false },
    "cookie-notice": { php: false },
    mainnavigation: { php: true },
    swiper: { php: false },
    accordion: { php: false },
    lightgallery: { php: false },
    tabs: { php: false },
  },
};
