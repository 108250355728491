<template>
  <div class="dropdown" :class="{ bounceIn: $parent.show }">
    <transition name="slide-fade">
      <div ref="dropdown" :class="{ 'is-touch': isTouchDevice }">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "DropdownNavigation",

  data() {
    return {
      isTouchDevice: false,
      closeTimeout: null,
      parentMenuItem: null,
    };
  },

  mounted() {
    this.parentMenuItem = this.$el.closest("li.menu-item");
    this.initializeNavigation(this.parentMenuItem);
    this.observeParentClass();

    this.clickEvent = (e) => {
      this.onClick(e);
    };
  },

  methods: {
    observeParentClass() {
      //   const parentLi = this.parentMenuItem.closest("li");
      //   if (!parentLi) return;
      //   const observer = new MutationObserver((mutations) => {
      //     mutations.forEach((mutation) => {
      //       if (mutation.type === "attributes" && mutation.attributeName === "class") {
      //         this.$parent.setParentHoveredState(parentLi.classList.contains("sw-hovered"));
      //         if (!this.$parent.isParentHovered) {
      //           this.close();
      //         }
      //       }
      //     });
      //   });
      //   observer.observe(parentLi, {
      //     attributes: true,
      //     attributeFilter: ["class"],
      //   });
    },

    initializeNavigation(menuItemLi) {
      const menuItem = menuItemLi.querySelector("a");
      // Mouse events
      menuItemLi.addEventListener("mouseenter", () => {
        if (window.innerWidth > 992) {
          const parentLi = menuItem.closest("li");
          if (parentLi) {
            this.closeAllOtherDropdowns();
            this.triggerShow();
          }
        }
      });

      menuItemLi.addEventListener("mouseleave", (e) => {
        const relatedTarget = e.relatedTarget;
        if (!this.$refs.dropdown?.contains(relatedTarget)) {
          this.close();
        }
      });

      // Click event
      menuItemLi.addEventListener("click", () => {
        if (window.innerWidth > 992) {
          const parentLi = menuItemLi.closest("li");
          if (parentLi && parentLi.classList.contains("sw-hovered")) {
            if (!this.$parent.show) {
              this.triggerShow();
            } else {
              this.close();
            }
          }
        }
      });

      // Keyboard events für Accessibility
      menuItem.addEventListener("keydown", (e) => {
        // Mobil deaktivieren da andere navi
        if (window.innerWidth < 992) {
          return;
        }

        if (e.key === "Enter" || e.key === " ") {
          e.preventDefault();
          if (!this.$parent.show) {
            this.triggerShow();
          } else {
            this.close();
          }
        }
      });

      // ESC-Event auf Dropdown-Ebene hinzufügen
      this.$refs.dropdown?.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          e.preventDefault();
          this.close();
          const nextMenuItem = menuItemLi.nextElementSibling?.querySelector("a");
          if (nextMenuItem) {
            nextMenuItem.focus();
          }
        }
      });

      // Focus events
      menuItem.addEventListener("focus", () => {
        if (window.innerWidth > 992) {
          this.closeAllOtherDropdowns();
          this.triggerShow();
        }
      });

      menuItem.addEventListener("blur", (e) => {
        if (!this.$refs.dropdown?.contains(e.relatedTarget)) {
          this.close();
        }
      });
    },

    onClick(e) {
      const elm = this.getDropdownElement();
      if (!elm) return;

      const rect = elm.getBoundingClientRect();
      if (!rect.contains(e.clientX, e.clientY)) {
        this.close();
      }
    },

    getDropdownElement() {
      return document.querySelector("div.header__dropdown");
    },

    triggerShow() {
      if (this.$parent.isDropdownDisabled()) {
        return;
      }

      if (!this.$parent.show) {
        window.addEventListener("click", this.clickEvent);
      }

      this.$parent.setShowState(true);
    },

    close() {
      if (this.$parent.show) {
        window.removeEventListener("click", this.clickEvent);
      }
      this.$parent.setShowState(false);
    },

    closeAllOtherDropdowns() {
      const allDropdowns = document.querySelectorAll(".dropdown");
      allDropdowns.forEach((dropdown) => {
        const vueInstance = dropdown.__vue__;
        if (vueInstance && vueInstance !== this && vueInstance.$parent.show) {
          vueInstance.close();
        }
      });
    },
  },

  beforeDestroy() {
    if (this.parentMenuItem) {
      this.parentMenuItem.removeEventListener("focus", this.triggerShow);
      this.parentMenuItem.removeEventListener("blur", this.close);
    }
    window.removeEventListener("click", this.clickEvent);
  },
};
</script>
